
import Component from "vue-class-component";
import Vue from "vue";
import { State } from "vuex-class";
import Btn from "@/components/Btn.vue";
import { State as StateStore } from "../store";
import * as Api from "@/types/graphql";
import { getApiKey } from "../store/mappers";
import { Prop } from "vue-property-decorator";
import validator from "validator";
import gql from "graphql-tag";
import { TAX_CODE_REGEX } from "@/utils/user";

@Component({
  components: { Btn },
})
export default class EditField extends Vue {
  value = "";
  loading = false;
  @Prop()
  labelKey: string;
  @Prop()
  multiline: boolean;
  @Prop()
  number: boolean;
  @Prop()
  showNote: boolean;
  @State((state: StateStore) => state.auth.user) user: Api.User;

  rules = [];

  mounted() {
    if (this.labelKey == "taxCode") {
      this.rules = [
        (v) => !!v || "Inserire il codice fiscale",
        (v) => !!v.match(TAX_CODE_REGEX) || "Codice fiscale non valido",
      ];
    }
    this.value = this.user[getApiKey(this.labelKey)];
  }

  getTitle() {
    return this.$t(this.labelKey + ".label");
  }

  getNote() {
    return this.$t(this.labelKey + ".note");
  }

  getCounter() {
    if (this.multiline) return 500;
    else undefined;
  }

  getPrependIcon() {
    switch (this.labelKey) {
      case "linkedin":
        return "mdi-linkedin";
      default:
        return "";
    }
  }

  async save() {
    if (
      (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      this.loading = true;
      if (this.labelKey == "taxCode") {
        this.value = this.value.toUpperCase();
      }
      const res = await this.$apollo.mutate({
        mutation: gql`
		mutation put($guid: ID!, $value: String) {
			updateUser(guid: $guid, ${getApiKey(this.labelKey)}: $value)
		}
	`,
        variables: {
          guid: this.user.guid,
          value: this.value,
        },
      });
      this.loading = false;
      if (res.data.updateUser) {
        this.$emit("close");
      }
      this.$emit("completed", this.value != "");
    }
  }

  reset() {
    this.value = "";
    this.save();
    this.$emit("completed", false);
  }
}
