
import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import Field from "@/components/Field.vue";
import Btn from "@/components/Btn.vue";
import EditField from "@/components/EditField.vue";
import EditBirthdayField from "@/components/EditBirthdayField.vue";
import EditCityField from "@/components/EditCityField.vue";
import { State as StateStore } from "../store";
import * as Api from "@/types/graphql";
import { getApiKey } from "../store/mappers";
import gql from "graphql-tag";
import { Action, State } from "vuex-class";

@Component({
  components: {
    Card,
    Field,
    Btn,
    EditField,
    EditBirthdayField,
    EditCityField,
  },
})
export default class ProfileEditView extends Vue {
  value = "";
  loading = false;
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action getUser;

  qualifications = [];

  mounted() {
    this.value = this.user[getApiKey(this.$route.params.labelKey)];
  }

  getTitle() {
    return this.$t(this.$route.params.labelKey + ".label");
  }

  getSubTitle() {
    return this.$t(this.$route.params.labelKey + ".addValue");
  }

  isMultiline() {
    return this.$route.query?.multiline;
  }

  isCustom() {
    return this.$route.query?.custom;
  }

  getCounter() {
    if (this.isMultiline()) return 500;
    else undefined;
  }

  getLabelKey() {
    return this.$route.params.labelKey;
  }

  getPrependIcon() {
    switch (this.$route.params.labelKey) {
      case "linkedin":
        return "mdi-linkedin";
      default:
        return "";
    }
  }

  async save() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
		mutation put($guid: ID!, $value: String) {
			updateUser(guid: $guid, ${getApiKey(this.$route.params.labelKey)}: $value)
		}
	`,
      variables: {
        guid: this.user.guid,
        value: this.value,
      },
    });
    this.loading = false;
    if (res.data.updateUser) {
      await this.getUser();
      this.$router.back();
    }
  }

  async saveQualifications() {
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $qualifications: [QualificationInput]) {
          updateUser(guid: $guid, qualifications: $qualifications)
        }
      `,
      variables: {
        guid: this.user.guid,
        qualifications: this.qualifications,
      },
    });
    if (res.data.updateUser) {
      await this.getUser();
      this.$router.back();
    }
  }

  async resetQualifications() {
    this.qualifications = [];
    await this.saveQualifications();
  }
}
