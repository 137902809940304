
import Component from "vue-class-component";
import Vue from "vue";
import { State } from "vuex-class";
import Btn from "@/components/Btn.vue";
import { State as StateStore } from "../store";
import * as Api from "@/types/graphql";
import { getApiKey } from "../store/mappers";
import { Prop } from "vue-property-decorator";
import gql from "graphql-tag";
import moment, { parseDate } from "@/plugins/moment";

@Component({
  components: { Btn },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
})
export default class EditBirthdayField extends Vue {
  date = null;
  menu = false;
  dateFormatted = "";

  loading = false;
  @Prop()
  showNote: boolean;
  @State((state: StateStore) => state.auth.user) user: Api.User;

  mounted() {
    this.date = new Date(this.parseDate(this.user.birth_date)).toISOString().substr(0, 10);
    this.dateFormatted = this.formatDate(this.date);
  }

  getNote() {
    return this.$t("birthday.note");
  }

  async save() {
    this.loading = true;
    const birthDate = parseDate(this.dateFormatted);
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $birthDate: String, $gioImp: Int) {
          updateUser(guid: $guid, birth_date: $birthDate, cna_gio_imp: $gioImp)
        }
      `,
      variables: {
        guid: this.user.guid,
        birthDate: this.date,
        gioImp: birthDate.diff(moment(), "years") * -1 <= 40 ? 1 : 0
      },
    });
    this.loading = false;
    if (res.data.updateUser) {
      this.$emit("close");
    }
    this.$emit("completed", this.date != null);
  }

  formatDate(date) {
    if (!date) return null;

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  parseDate(date) {
    if (!date) return null;

    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  reset() {
    this.date = null;
    this.menu = false;
    this.dateFormatted = null;
    this.save();
    this.$emit("completed", false);
  }
}
