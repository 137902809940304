import _ from 'lodash';

const keys = {
	taxCode: 'tax_code',
	professionalQualification: 'prof_qualification',
	telephone: 'phone',
};

export function getApiKey(key: string): string {
	if (_.has(keys, key)) {
		return keys[key];
	} else return key;
}
