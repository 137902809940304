
import { Component, Prop, Vue } from "vue-property-decorator";
import * as Api from "@/types/graphql";
import { State as StateStore } from "@/store";
import _ from "lodash";
import Btn from "@/components/Btn.vue";
import initGMaps from "@/plugins/gmaps";
import gql from "graphql-tag";
import { Action, State } from "vuex-class";
import { StatusCodes } from "@/utils/status";

@Component({
  components: { Btn },
  watch: {
    search(val) {
      if (val != null) {
        if (val.length > 3) {
          this.initGoogle("it_IT");

          if (this.cityIsLoading) return;

          this.cityIsLoading = true;

          const service = new this.google.maps.places.AutocompleteService();
          service.getPlacePredictions(
            {
              input: val,
              types: ["(cities)"],
              location: new this.google.maps.LatLng(53.106947, 6.918427),
              radius: 2156990,
              strictbounds: true,
            },
            (predictions, status) => {
              this.cities = [];
              predictions.map((item) => {
                this.cities.push({
                  text: item.description,
                  value: item.description,
                });
              });
              this.cityIsLoading = false;
            }
          );
        }
      }
    },
  },
})
export default class EditCityField extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Prop({ default: false })
  dense: boolean;
  @Prop({ default: false })
  showNote: boolean;
  @Prop({ default: false })
  showActions: boolean;

  city = {};
  cityError = false;
  cityErrorMsg = [];
  cities = [];
  cityIsLoading = false;
  search = null;
  entries = [];

  google = undefined;

  loading = false;

  async mounted() {
    await this.initGoogle();
    if (!_.isUndefined(this.user.city_residence)) {
      this.cities.push({
        text: this.user.city_residence,
        value: this.user.city_residence,
      });
      this.city = this.user.city_residence;
    } else {
      this.cities = [];
      this.entries = [];
    }
  }

  async initGoogle() {
    if (_.isUndefined(this.google)) {
      this.google = await initGMaps("it_IT");
    }
  }

  checkCity() {
    if (_.isEmpty(this.city)) {
      this.setCityError(true);
    } else {
      this.setCityError(false);
      if (!this.showActions) {
        this.$emit("selected", this.city);
      }
    }
  }

  setCityError(error) {
    this.cityError = error;
    this.cityErrorMsg = error ? [this.$t("cityNotValid")] : [];
  }

  async save() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $value: String) {
          updateUser(guid: $guid, city_residence: $value)
        }
      `,
      variables: {
        guid: this.user.guid,
        value: this.city,
      },
    });
    this.loading = false;
    if (res.data.updateUser) {
      this.$emit("close");
    }
  }
}
